import React, { useState, useEffect } from "react";
import "./style.css";
import { getExchangeRates } from "./CurrencyService.js";
import { Swiper, SwiperSlide } from "swiper/react";
import MarqueGuncelHaberler from "./marque-guncel-haberler.jsx";
import * as Realm from "realm-web";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import { format } from "date-fns";
import trLocale from "date-fns/locale/tr"; // Türkçe dil dosyasını ekleyin

function Home() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [exchangeRates, setExchangeRates] = useState({});
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [user, setUser] = useState();
  const [homeTexts, setHomeTexts] = useState({
    text1: "Vatanını en çok sevenler,\nGörevini en iyi yapanlardır.",
    text2: "HOŞ GELDİNİZ",
    text3: "KOÇ KULELERİ B BLOK YÖNETİMİ",
    text4: "kulebblok.com",
    text5: "kockuleleribblok@hotmail.com",
    text6: "+90 542 504 3661",
  });

  const app = new Realm.App({ id: "application-0-onino" });

  // Date interval useEffect
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // Exchange rates useEffect
  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const rates = await getExchangeRates();
        setExchangeRates(rates);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchExchangeRates();
  }, []);

  // MongoDB ve Video yönetimi useEffect
  useEffect(() => {
    let homeTextsWatcherRef = null;
    let videosWatcherRef = null;
    let intervalRef = null; // Yedek çözüm için interval referansı

    const login = async () => {
      const user = await app.logIn(Realm.Credentials.anonymous());
      setUser(user);

      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const videosCollection = mongodb.db("test").collection("videos");
      const homeTextsCollection = mongodb.db("test").collection("hometexts");

      // Verileri yükleyen fonksiyon
      const loadData = async () => {
        try {
          console.log("Veriler yükleniyor...");

          // Videoları al
          const allVideos = await videosCollection.find({});
          if (allVideos.length > 0) {
            console.log("Videolar yüklendi:", allVideos[0].videos.length);
            setVideos(allVideos[0].videos);
          }

          // Home metinlerini al
          const allHomeTexts = await homeTextsCollection.find({});
          if (allHomeTexts.length > 0) {
            console.log("Home metinleri yüklendi:", allHomeTexts[0]);
            const textsData = allHomeTexts[0];

            // text1 için özel işlem - MongoDB'den gelen metinde literal \n karakteri olabilir
            let text1Value = textsData.text1 || homeTexts.text1;
            // Eğer text1 içinde literal \n karakteri varsa, gerçek satır atlama karakteriyle değiştir
            if (
              text1Value &&
              typeof text1Value === "string" &&
              text1Value.includes("\\n")
            ) {
              text1Value = text1Value.replace(/\\n/g, "\n");
            }

            setHomeTexts({
              text1: text1Value,
              text2: textsData.text2 || homeTexts.text2,
              text3: textsData.text3 || homeTexts.text3,
              text4: textsData.text4 || homeTexts.text4,
              text5: textsData.text5 || homeTexts.text5,
              text6: textsData.text6 || homeTexts.text6,
            });
          }
        } catch (error) {
          console.error("Veri yükleme hatası:", error);
        }
      };

      // İlk veri yüklemesi
      await loadData();

      try {
        console.log("Change Streams dinleyicileri kuruluyor...");

        // Tüm koleksiyonu izlemek yerine, belirli bir belgeyi izle
        const homeTextsQuery = { _id: { $exists: true } }; // Tüm belgeleri eşleştiren sorgu
        const homeTextsOptions = {
          fullDocument: "updateLookup", // Değişiklik olduğunda tam belgeyi al
        };

        // Koleksiyondaki değişiklikleri dinle
        const homeTextsWatcher = await homeTextsCollection.watch(
          homeTextsQuery,
          homeTextsOptions
        );
        homeTextsWatcherRef = homeTextsWatcher;

        console.log("hometexts için Change Stream başlatıldı");

        homeTextsWatcher.onNext(async (changeEvent) => {
          console.log(
            "hometexts koleksiyonunda değişiklik algılandı!",
            JSON.stringify(changeEvent)
          );

          // Değişiklik türünü kontrol et
          if (
            changeEvent.operationType === "insert" ||
            changeEvent.operationType === "update" ||
            changeEvent.operationType === "replace"
          ) {
            // Tam belgeyi al
            const fullDocument = changeEvent.fullDocument;
            if (fullDocument) {
              console.log("Yeni hometext belgesi:", fullDocument);

              // text1 için özel işlem
              let text1Value = fullDocument.text1 || homeTexts.text1;
              if (
                text1Value &&
                typeof text1Value === "string" &&
                text1Value.includes("\\n")
              ) {
                text1Value = text1Value.replace(/\\n/g, "\n");
              }

              setHomeTexts({
                text1: text1Value,
                text2: fullDocument.text2 || homeTexts.text2,
                text3: fullDocument.text3 || homeTexts.text3,
                text4: fullDocument.text4 || homeTexts.text4,
                text5: fullDocument.text5 || homeTexts.text5,
                text6: fullDocument.text6 || homeTexts.text6,
              });
            } else {
              // Tam belge yoksa, tüm verileri yeniden yükle
              console.log("Tam belge yok, tüm verileri yeniden yüklüyorum");
              await loadData();
            }
          } else {
            // Diğer değişiklik türleri için tüm verileri yeniden yükle
            console.log(
              "Farklı bir değişiklik türü, tüm verileri yeniden yüklüyorum"
            );
            await loadData();
          }
        });

        homeTextsWatcher.onError((error) => {
          console.error("hometexts Change Stream hatası:", error);
        });

        // Video koleksiyonu için de dinleyici ekle
        const videosQuery = { _id: { $exists: true } };
        const videosOptions = {
          fullDocument: "updateLookup",
        };

        const videosWatcher = await videosCollection.watch(
          videosQuery,
          videosOptions
        );
        videosWatcherRef = videosWatcher;

        console.log("videos için Change Stream başlatıldı");

        videosWatcher.onNext(async (changeEvent) => {
          console.log(
            "videos koleksiyonunda değişiklik algılandı!",
            JSON.stringify(changeEvent)
          );

          if (
            changeEvent.operationType === "insert" ||
            changeEvent.operationType === "update" ||
            changeEvent.operationType === "replace"
          ) {
            const fullDocument = changeEvent.fullDocument;
            if (fullDocument && fullDocument.videos) {
              console.log("Yeni video belgesi:", fullDocument.videos.length);
              setVideos(fullDocument.videos);
            } else {
              console.log(
                "Tam video belgesi yok, tüm verileri yeniden yüklüyorum"
              );
              await loadData();
            }
          } else {
            console.log(
              "Farklı bir video değişiklik türü, tüm verileri yeniden yüklüyorum"
            );
            await loadData();
          }
        });

        videosWatcher.onError((error) => {
          console.error("videos Change Stream hatası:", error);
        });

        // Yedek çözüm: Her 10 saniyede bir verileri kontrol et
        // Bu, Change Streams çalışmadığında bir güvenlik önlemi olarak kullanılır
        intervalRef = setInterval(async () => {
          console.log("Periyodik kontrol çalışıyor...");
          await loadData();
        }, 10000);
      } catch (error) {
        console.error("Gerçek zamanlı dinleyici hatası:", error);
        // Hata durumunda yine de verileri yükle
        await loadData();

        // Dinleyici çalışmazsa, daha sık periyodik kontrol mekanizması kur
        console.log(
          "Change Streams çalışmadı, periyodik kontrol mekanizması kuruluyor..."
        );
        intervalRef = setInterval(async () => {
          console.log("Periyodik kontrol çalışıyor...");
          await loadData();
        }, 5000); // Her 5 saniyede bir verileri kontrol et
      }
    };

    login();

    // useEffect temizleme fonksiyonu
    return () => {
      // Dinleyicileri kapat
      if (homeTextsWatcherRef) {
        console.log("hometexts Change Stream kapatılıyor");
        homeTextsWatcherRef.close();
      }
      if (videosWatcherRef) {
        console.log("videos Change Stream kapatılıyor");
        videosWatcherRef.close();
      }
      // Interval'i temizle
      if (intervalRef) {
        console.log("Periyodik kontrol durduruluyor");
        clearInterval(intervalRef);
      }
    };
  }, []);

  // Video değişim yönetimi
  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  // Video hata yönetimi
  const handleVideoError = (e) => {
    console.error("Video yükleme hatası:", e);
    handleVideoEnd();
  };

  // Video yükleme yönetimi
  const handleVideoLoad = () => {
    console.log("Video yüklendi");
  };

  // Tarih ve saat bilgisini belirli bir formata göre formatla
  const formattedDate1 = format(currentDate, "dd.MM.yyyy ", {
    locale: trLocale,
  });

  // Tarih ve saat bilgisini belirli bir formata göre formatla
  const formattedDate2 = format(currentDate, "EEEE", {
    locale: trLocale,
  });
  // Tarih ve saat bilgisini belirli bir formata göre formatla
  const formattedDate3 = format(currentDate, "HH:mm:ss", {
    locale: trLocale,
  });

  const dolarCurency = exchangeRates && exchangeRates["TRY"];
  const euroCurency =
    exchangeRates && exchangeRates["TRY"] / exchangeRates["EUR"];
  const gbpCurency =
    exchangeRates && exchangeRates["TRY"] / exchangeRates["GBP"];
  const aznCurency =
    exchangeRates && exchangeRates["TRY"] / exchangeRates["AZN"];

  const currencies = [
    {
      name: "USD",
      class: "dolarbg",
      tlValue: dolarCurency ? dolarCurency.toFixed(2) : "",
    },
    {
      name: "EUR",
      class: "eurobg",
      tlValue: euroCurency ? euroCurency.toFixed(2) : "",
    },
    {
      name: "GBP",
      class: "gbpbg",
      tlValue: gbpCurency ? gbpCurency.toFixed(2) : "",
    },
    {
      name: "AZN",
      class: "aznbg",
      tlValue: aznCurency ? aznCurency.toFixed(2) : "",
    },
  ];

  const linkIptal = (e) => {
    e.preventDefault();
  };

  // text1'i satırlara böl
  const text1Lines = homeTexts.text1.split("\n");

  return (
    <div style={{ height: "100vh" }}>
      <div className="container-fluid home-layout">
        <div className="row">
          <div className="col-lg-3 left-sidebar">
            <div className="left-menu cart">
              <div>
                <h4>
                  {text1Lines[0]}
                  <br />
                  {text1Lines[1] || ""}
                </h4>
                <img src="/images/ataturk.svg"></img>
              </div>
              <div></div>

              <div className="auto-date-div">
                <span className="auto-date-tarih">{formattedDate1}</span>
                <span className="auto-date-gun">{formattedDate2}</span>
                <span className="auto-date-saat">{formattedDate3}</span>
              </div>
              <div className="col-12">
                {loading ? (
                  <p>Veriler yükleniyor...</p>
                ) : (
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={false}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {currencies.map((currency, index) => (
                      <SwiperSlide>
                        <div
                          className={currency.class + " currency-div"}
                          key={index}
                        >
                          <h2 className="currency-name">{currency.name}</h2>
                          <p className="currency-tl-value">
                            {currency.tlValue + " ₺"}
                          </p>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
              <div className="screenoneone">
                <div className="hava-durumu-widget">
                  <a
                    id="ms-informer-link-058c25c2b49091649984549b06e6ba52"
                    className="ms-informer-link"
                    href="https://meteodays.com/tr/weather/overview/ankara"
                    onClick={linkIptal}
                  >
                    Ankara hava durumu
                  </a>
                </div>
                <div className="contact-div">
                  <h5>{homeTexts.text4}</h5>
                  <h5>{homeTexts.text5}</h5>
                  <h5>{homeTexts.text6}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 right-sidebar">
            <div className="header cart giris-slogan">
              <h1 className="hos-geldiniz">{homeTexts.text2}</h1>
              <h3 className="yonetim-ad">{homeTexts.text3}</h3>
            </div>
            <div
              className="video cart mt-3"
              style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
              }}
            >
              {!!user && videos.length > 0 && (
                <video
                  key={currentVideoIndex}
                  controls
                  autoPlay
                  muted
                  onEnded={handleVideoEnd}
                  onError={handleVideoError}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <source src={videos[currentVideoIndex]} type="video/mp4" />
                  Tarayıcınız video elementini desteklemiyor.
                </video>
              )}
            </div>
            <div className="row">
              <div className="col-12 footer-box">
                <div className="cart footer">
                  <div>
                    {" "}
                    <button className="guncel-button">Güncel</button>
                  </div>
                  <MarqueGuncelHaberler />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
